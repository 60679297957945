exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-index-js": () => import("./../../../src/pages/cookie/index.js" /* webpackChunkName: "component---src-pages-cookie-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-muffin-index-js": () => import("./../../../src/pages/muffin/index.js" /* webpackChunkName: "component---src-pages-muffin-index-js" */),
  "component---src-pages-pie-index-js": () => import("./../../../src/pages/pie/index.js" /* webpackChunkName: "component---src-pages-pie-index-js" */),
  "component---src-pages-punch-index-js": () => import("./../../../src/pages/punch/index.js" /* webpackChunkName: "component---src-pages-punch-index-js" */)
}

